import React from 'react'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

const ExpansionPanel = withStyles({
  root: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(Accordion)

export default function StudentList({ gidm, email, students }) {
  const [expanded, setExpanded] = React.useState(true)

  // const handleChange = (panel) => (event, expanded) =>
  //   setExpanded(expanded ? panel : false)

  if (students.length < 1)
    return (
      <Alert severity="warning">
        <AlertTitle>No Records Access Granted</AlertTitle>
        This account ({email}) has not been granted access to any student
        records. Your student can grant access to you by visiting their RAMweb
        account.
        <br />
        <a
          href="https://parentsandfamily.colostate.edu/how-to-gain-access/"
          target="_blank"
          rel="noopener noreferrer"
        >
          How do I gain access to the FAMweb system?
        </a>
      </Alert>
    )

  const icons = {
    AREG_LCTGRDS: 'fa fa-fw fa-user-graduate',
    AREG_WKSCHED: 'fa fa-fw fa-calendar-alt', //schedule image
    AROS_BILLING: 'fa fa-fw fa-file-invoice',
    AREG_GLOBAL: 'fa fa-fw fa-info-circle',
    AREG_UXSCRPT: 'fa fa-fw fa-file-alt', //Transcript img
    AROS_TAX: 'fa fa-fw fa-file-invoice-dollar',
    AREG_FINAID: 'fa fa-fw fa-dollar-sign',
  }

  const token = window.localStorage.getItem('spoof_token') || ''
  let make_payment
  return students.map((student) => (
    <ExpansionPanel
      key={`${gidm}-${student.pidm}`}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{student.fullName}</Typography>
      </AccordionSummary>
      <Divider />
      <List>
        {student.authorizations.forEach((authorization) => {
          if (authorization.code === "AROS_BILLING") {
            make_payment = <ListItem
              key={`${gidm}-${student.pidm}-${authorization.code}-payments`}
              component={['AROS_BILLING'].indexOf(
                authorization.code
              ) > -1
                ? 'a'
                : Link}
              to={`/link/${gidm}/${student.pidm}/${authorization.code}`}
              href={`/external/link/${gidm}/${student.pidm}/${authorization.code}?action=payment&token=${token}`}
              button
            >
              <ListItemAvatar>
                <Avatar>
                  <Icon className={"far fa-credit-card"} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Make a Payment"}
                secondary={'Granted access on ' + authorization.activityDate}
              />
            </ListItem>
          }
        })}
        {make_payment}
        {student.authorizations.map((authorization) => (
          <ListItem
            key={`${gidm}-${student.pidm}-${authorization.code}`}
            component={
              ['AROS_BILLING', 'AREG_FINAID', 'AROS_TAX'].indexOf(
                authorization.code
              ) > -1
                ? 'a'
                : Link
            }
            to={`/link/${gidm}/${student.pidm}/${authorization.code}`}
            href={`/external/link/${gidm}/${student.pidm}/${authorization.code}?token=${token}`}
            button
          >
            <ListItemAvatar>
              <Avatar>
                <Icon className={icons[authorization.code]} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={authorization.type.description}
              secondary={'Granted access on ' + authorization.activityDate}
            />
          </ListItem>
        ))}
      </List>
    </ExpansionPanel>
  ))
}
// {authorization.code === "AROS_BILLING"
//           ? <ListItem
//             key={`${gidm}-${student.pidm}-${authorization.code}-payments`}
//             component={<Link to={"https://ramweb.colostate.edu/bfs/Payments/Payments_cart.aspx"} >Make a Payment</Link>}
//           >
//             <ListItemAvatar>
//               <Avatar>
//                 <Icon className={"far fa-credit-card"} />
//               </Avatar>
//             </ListItemAvatar>
//             <ListItemText
//               primary={authorization.type.description}
//               secondary={'Granted access on ' + authorization.activityDate}
//             />
//           </ListItem> : null}