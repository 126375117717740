import React from 'react'
import { Link } from 'react-router-dom'

import LoginOrLogout from './pages/auth/LoginOrLogout'

export default function TopMenu() {
  return (
    <div>
      <Link to="/">Home</Link>
      <Link to="/faq">FAQs</Link>
      <LoginOrLogout />
    </div>
  )
}
