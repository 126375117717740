import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Helmet } from 'react-helmet'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import SocialOption from './Options/Social'
import EIDOption from './Options/EID'

const query = gql`
  query getConfiguration {
    getConfiguration {
      socialUrl
      shibbolethUrl
      logins {
        eid
        legacy
        social
      }
    }
  }
`

export default function LoginOptions() {
  const { loading, /*error,*/ data } = useQuery(query)

  if (loading || !data) return null
  // if (error) return `Error!: ${error}`;

  const { shibbolethUrl, socialUrl, logins } = data.getConfiguration
  // const loginCount = Object.keys(logins).filter(key => key !== "__typename")
  //   .length;
  const sm = 12 / 2 // loginCount

  return (
    <div>
      <Helmet>
        <style type="text/css">
          {`.header {
              height: "100px";
            }`}
        </style>
      </Helmet>

      <Card>
        <React.Fragment>
          <CardContent>
            <Typography component="p">
              If you do not wish to access FAMweb for paying electronically,
              please visit{' '}
              <a href="https://financialaid.colostate.edu/paying-your-bill/">
                https://financialaid.colostate.edu/paying-your-bill/
              </a>{' '}
              to see all accepted payment options.
            </Typography>
          </CardContent>
        </React.Fragment>
      </Card>
      <br />
      <Typography gutterBottom variant="h6" align="center">
        PLEASE REVIEW THE OPTIONS BELOW AND MAKE A SELECTION:
      </Typography>
      <br />
      <Grid container spacing={3}>
        {logins.eid && (
          <Grid item xs={12} sm={sm}>
            <EIDOption shibbolethUrl={shibbolethUrl} />
          </Grid>
        )}
        {logins.social && (
          <Grid item xs={12} sm={sm}>
            <SocialOption socialUrl={socialUrl} />
          </Grid>
        )}
      </Grid>
      <br />
    </div>
  )
}
