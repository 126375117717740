import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import idx from 'idx'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import InfoIcon from '@material-ui/icons/Info'
import Typography from '@material-ui/core/Typography'

const query = gql`
  query getConfiguration {
    getConfiguration {
      maintenanceMessage
    }
  }
`

export default function MaintenanceMessage() {
  const { loading, error, data } = useQuery(query)

  if (loading) return null
  if (error) return `Error!: ${error}`

  const configuration = idx(data, (_) => _.getConfiguration)

  return configuration.maintenanceMessage ? (
    <Card style={{ marginBottom: 10 }}>
      <CardContent>
        <Typography variant="h5" component="h2">
          <InfoIcon /> {configuration.maintenanceMessage}
        </Typography>
        {/* <Typography component="p">
          <InfoIcon /> {configuration.maintenanceMessage}
        </Typography> */}
      </CardContent>
    </Card>
  ) : (
    false
  )
}
