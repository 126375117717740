import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useCookies } from 'react-cookie'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const query = gql`
  query getMyUser {
    getMyUser {
      email
    }
  }
`

export default function Ferpa() {
  const [, setCookie, removeCookie] = useCookies(['ferpa'])
  const { data } = useQuery(query)

  if (!data.getMyUser) return null

  function onAgree() {
    const expires = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    )
    setCookie('ferpa', data.getMyUser.email, { path: '/', expires })
    window.location = '/'
  }

  function onDisagree() {
    removeCookie('ferpa')
    window.location = '/'
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">FERPA agreement</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          In accordance with The Family Educational Rights and Privacy Act
          (FERPA), 20 U.S.C. 1232g, and implementing regulations, 34 C.F.R. Part
          99, section 99.31(a)(1), you are hereby notified that all information
          obtained through this website is provided by the student upon the
          condition that you will not disclose the information to any other
          party, nor permit any other party access to this record, without the
          prior consent of the student.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} color="primary">
          I Disagree
        </Button>
        <Button onClick={onAgree} variant="contained" color="primary" autoFocus>
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
