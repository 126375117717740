import './default.css'

import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

function setupMenu() {
  const buttonEl = document.getElementById('mobile-menu')
  const nav = document.querySelector('nav.sidemenu')

  if (!buttonEl || !nav) return

  buttonEl.addEventListener('click', function() {
    nav.classList.remove('away')
  })

  document.body.addEventListener('click', clickEv)
  document.body.addEventListener('touchend', clickEv)

  function clickEv(e) {
    const t = e.target
    const inButton = buttonEl === t || buttonEl.contains(t)
    const inNav = nav === t || nav.contains(t)

    if (inButton || inNav) return

    nav.classList.add('away')
  }
}

function setupSticky() {
  const stickyEl = document.querySelector('.sticky')

  if (window.getComputedStyle(stickyEl)['position'] === 'sticky') return

  let stuck = false
  const stickPoint =
    stickyEl.offsetTop + document.querySelector('html').offsetTop

  window.addEventListener('scroll', function() {
    if (stuck && window.pageYOffset <= stickPoint) {
      stickyEl.classList.remove('stuck')
      stuck = false
    } else if (!stuck && window.pageYOffset > stickPoint) {
      stickyEl.classList.add('stuck')
      stuck = true
    }
  })
}

export default function Theme(props) {
  useEffect(() => {
    setupMenu()
    setupSticky()
  }, [])

  return (
    <div>
      <Helmet
        title={props.headerTitle}
        meta={[
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, user-scalable=no',
          },
        ]}
        link={[
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css?family=Ubuntu+Condensed',
          },
        ]}
      />
      <header>
        <div id="header-top">
          <div className="sheet">
            <nav />
            <a
              target="_blank"
              href="http://www.colostate.edu"
              className="logo"
              aria-label="Colorado StateUniversity"
            />
          </div>
        </div>
        <div id="header-alert">
          <div className="sheet">{props.headerAlert}</div>
        </div>
        <div id="header-message">
          <div className="sheet">{props.headerMessage}</div>
        </div>
        <div id="header-bottom" className="sticky">
          <div className="sheet">
            <div className="title">
              <button id="mobile-menu" aria-label="Mobile Menu">
                <span className="bar" />
                <span className="bar" />
                <span className="bar" />
              </button>
              <h1>
                <a href="/">{props.headerTitle || 'Untitled'}</a>
              </h1>
            </div>
            <nav>{props.topMenu}</nav>
          </div>
        </div>
        <div id="top-cover-image-wrapper">
          <div
            id="top-cover-image-inner"
            data-anchor-target="#skrollr &gt; main"
            data-0="top:-10%"
            data-top-top="top:0%"
          />
        </div>
      </header>
      <main>
        <div className="sheet">
          <div className="sheet-content">{props.main}</div>
          <nav className="sidemenu away">{props.sideMenu}</nav>
        </div>
      </main>
      <footer>
        <div id="footer-bottom">
          <div className="sheet">
            <div id="copyright">
              &copy; {new Date().getFullYear()} Colorado State University
            </div>
            <div id="footer-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.colostate.edu/info-contact.aspx"
              >
                Contact CSU
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.colostate.edu/info-disclaimer.aspx"
              >
                Disclaimer
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.colostate.edu/info-equalop.aspx"
              >
                Equal Opportunity
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.colostate.edu/info-privacy.aspx"
              >
                Privacy Statement
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
