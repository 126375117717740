import React from 'react'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

import { withSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const mutation = gql`
  mutation inviteUser($gidm: ID, $requester: ID!) {
    inviteUser(gidm: $gidm, requester: $requester)
  }
`

export default withSnackbar(
  ({ gidm, requester, enqueueSnackbar, children }) => (
    <Mutation mutation={mutation}>
      {(inviteUser, { loading, error }) => (
        <Button
          type="submit"
          variant="outlined"
          size="small"
          onClick={async () => {
            const response = await inviteUser({
              variables: { gidm, requester },
            })
            if (response.data.inviteUser) {
              enqueueSnackbar('Invitation sent!', { variant: 'success' })
            } else {
              enqueueSnackbar('There was an error! Try again.', {
                variant: 'error',
              })
            }
          }}
          disabled={loading}
          endIcon={<Icon className={'fas fa-paper-plane'} color="action" />}
        >
          {children}
        </Button>
      )}
    </Mutation>
  )
)
