import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import StudentList from './StudentList'

const useStyles = makeStyles({
  root: {
    marginBottom: '10px',
  },
  title: {
    color: '#ffffff',
  },
  content: {
    backgroundColor: '#2A5135',
    color: '#ffffff',
  },
})

export default function Account({ gidm, email, students }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} variant="h6" component="h6">
          Records Access granted to: {email}
        </Typography>
      </CardContent>

      <StudentList gidm={gidm} email={email} students={students} />
    </Card>
  )
}
