import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const query = gql`
  query getMyUser {
    getMyUser {
      email
      eduPersonPrincipalName
      givenName
      sn
      type
      verified
      roles
    }
  }
`
export const AuthContext = React.createContext()

export default function AuthProvider(props) {
  const { loading, error, data } = useQuery(query)

  if (error || loading || !data) return null

  const value = {
    user: data.getMyUser,
  }

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}
