import React from 'react'
// import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import idx from 'idx'
import Helmet from 'react-helmet'

import Collapse from '@material-ui/core/Collapse'

// import Divider from '@material-ui/core/Divider'
// import Drawer from '@material-ui/core/Drawer'
// import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
// import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import LogInOrOut from './pages/auth/LoginOrLogout'

const query = gql`
  query getMyUser {
    getMyUser {
      email
      eduPersonPrincipalName
      givenName
      sn
      roles
      type
    }
  }
`

function General({ user }) {
  return (
    <div>
      <h2>Menu</h2>
      <List component="div" disablePadding>
        <ListItem button component="a" href="/">
          <ListItemText primary={'Home'} />
        </ListItem>
        <ListItem button component="a" href="/faq">
          <ListItemText primary={'FAQs'} />
        </ListItem>
        <LogInOrOut type={'ListItem'} />
      </List>
    </div>
  )
}

function Admin({ user }) {
  if (!user || !idx(user, (_) => _.roles.indexOf('ADMIN') > -1)) {
    return null
  }

  return (
    <div>
      <h2>My Access</h2>
      <List component="div" disablePadding>
        <ListItem button component="a" href="/admin">
          <ListItemText primary={'Admin'} />
        </ListItem>
      </List>
    </div>
  )
}

function Links() {
  return (
    <List>
      <h2>Links</h2>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            component="a"
            // key={'Academic Calendar'}
            target="_blank"
            href="https://calendar.colostate.edu/academic/"
            rel="noopener noreferrer"
          >
            <ListItemText primary={'Academic Calendar'} />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://maps.colostate.edu/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Campus Map" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="http://busfin.colostate.edu/Depts/Cash_Office.aspx"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Cashier's Office" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://financialaid.colostate.edu/cost-of-attendance/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Cost of Attendance" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://financialaid.colostate.edu/payment-plan/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="CSU Payment Plan" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="http://www.parentsandfamily.colostate.edu/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Parent &amp; Family Programs" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://ramcash.colostate.edu/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="RamCash" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://registrar.colostate.edu/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Registrar" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://financialaid.colostate.edu/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Office of Financial Aid" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="http://tuition.colostate.edu/Main.aspx"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Tuition Calculator" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://health.colostate.edu/health-insurance-requirement/"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Student Health Insurance Requirement" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://health.colostate.edu/wp-content/uploads/sites/3/2024/01/CSUHN-Family-History-Form.pdf"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Family Health History Form" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  )
}

export default function SideMenu() {
  const { loading, error, data } = useQuery(query)

  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return null
  }

  const user = idx(data, (_) => _.getMyUser)

  return (
    <>
      <Helmet>
        <script
          async
          type="text/javascript"
          src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        ></script>
        <script>
          {`function googleTranslateElementInit() {
            new google.translate.TranslateElement(
              { pageLanguage: 'en' },
              'google_translate_element'
            )
          }`}
        </script>
      </Helmet>
      <ul>
        <General user={user} />
        {user && <Admin user={user} />}
        {user && <Links />}

        <br />
        <div id="google_translate_element"></div>
      </ul>
    </>
  )
}
