import React from 'react'

import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import SpoofButton from './SpoofButton'

export default function LoginListItem({
  gidm,
  email,
  login: { eduPersonPrincipalName, type },
}) {
  let loginLabel = ''

  if (eduPersonPrincipalName.indexOf('@google.com') > -1) {
    loginLabel = 'Google'
  } else if (
    eduPersonPrincipalName.indexOf('@microsoft.com') > -1 ||
    eduPersonPrincipalName.indexOf('@msn.com') > -1 ||
    eduPersonPrincipalName.indexOf('@outlook.com') > -1 ||
    eduPersonPrincipalName.indexOf('@hotmail.com') > -1 ||
    eduPersonPrincipalName.indexOf('@live.com') > -1
  ) {
    loginLabel = 'Microsoft'
  } else if (eduPersonPrincipalName.indexOf('@facebook.com') > -1) {
    loginLabel = 'Facebook'
  } else {
    loginLabel = 'EID'
  }

  return (
    <React.Fragment>
      <Divider />
      <ListItem>
        <ListItemText primary={`${loginLabel} - ${eduPersonPrincipalName}`} />
        <ListItemSecondaryAction>
          <SpoofButton
            email={email}
            gidm={gidm}
            eduPersonPrincipalName={eduPersonPrincipalName}
          >
            Spoof Login
          </SpoofButton>
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  )
}
