import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Grades.css'

const query = gql`
  query getGrades($pidm: ID!) {
    getGrades(pidm: $pidm)
  }
`

export default function Grades() {
  const { pidm } = useParams()
  const { loading, error, data } = useQuery(query, { variables: { pidm } })

  if (loading) return <CircularProgress />
  if (error) return `Error!: ${error}`

  return (
    <div>
      <h2>Grades</h2>
      <Card>
        <CardContent>
          {!loading && data && (
            <div
              dangerouslySetInnerHTML={{
                __html: data.getGrades,
              }}
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
