import * as React from 'react'
import { useParams } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'

export default function WeeklySchedule() {
  const { gidm, pidm } = useParams()
  const token = window.localStorage.getItem('spoof_token') || ''

  return (
    <div>
      <h2>Weekly Class Schedule</h2>
      <Paper style={{ padding: 20 }} elevation={1}>
        <div>
          <iframe
            title="Class Schedule"
            src={`/external/link/${gidm}/${pidm}/AREG_WKSCHED?token=${token}`}
            frameborder="0"
            width="100%"
            height="1000"
          />
        </div>
      </Paper>
    </div>
  )
}
