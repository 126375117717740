import React from 'react'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

import { withSnackbar } from 'notistack'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const mutation = gql`
  mutation spoof($gidm: ID!, $eduPersonPrincipalName: ID!) {
    spoof(gidm: $gidm, eduPersonPrincipalName: $eduPersonPrincipalName)
  }
`

export default withSnackbar(
  ({ gidm, eduPersonPrincipalName, enqueueSnackbar, children }) => (
    <Mutation
      mutation={mutation}
      update={(cache, { data: { spoof } }) => {
        if (spoof) {
          localStorage.setItem('spoof_token', spoof)
          window.location = '/'
        } else {
          enqueueSnackbar('There was an error! Try again.', {
            variant: 'error',
          })
        }
      }}
    >
      {(spoof, { loading, error }) => (
        <Button
          type="submit"
          variant="outlined"
          size="small"
          onClick={() => spoof({ variables: { gidm, eduPersonPrincipalName } })}
          disabled={loading}
          endIcon={<Icon className={'fas fa-user-secret'} color="action" />}
        >
          {children}
        </Button>
      )}
    </Mutation>
  )
)
