import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { withClientState } from "apollo-link-state";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

import resolvers from "./resolvers";

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: "/graphql",
  credentials: "include"
});

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem("spoof_token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const stateLink = withClientState({
  cache,
  resolvers
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([stateLink, authLink, httpLink])
});

export { client, cache };
