import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const logoutMutation = gql`
  mutation logout {
    logout @client
  }
`

export default function LogoutLink({ type }) {
  const [logout] = useMutation(logoutMutation)

  if (type === 'ListItem') {
    return (
      <ListItem button onClick={() => logout()}>
        <ListItemText primary={'Logout'} />
      </ListItem>
    )
  }

  return (
    <React.Fragment>
      {
        // eslint-disable-next-line
      }
      <a href="#" onClick={() => logout()}>
        Logout
      </a>
    </React.Fragment>
  )
}
