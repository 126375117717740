import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Alert, AlertTitle } from '@material-ui/lab'

import Account from './Account'

const query = gql`
  query {
    getMyAccounts {
      firstName
      lastName
      email
      gidm
      students {
        fullName
        pidm
        authorizations {
          code
          type {
            id
            description
            email
          }
          activityDate
          startDate
          endDate
        }
      }
    }
  }
`

export default function Accounts() {
  const { loading, error, data } = useQuery(query)

  if (loading) return <div>Loading...</div>
  if (error) return `Error!: ${error}`

  if (data.getMyAccounts.length < 1)
    return (
      <div>
        <h2>Accounts</h2>
        <Alert severity="warning">
          <AlertTitle>No accounts are linked to your login</AlertTitle>
          Your login currently does not have any accounts linked to it. Your
          student can grant access to you by visiting their RAMweb account.
          <br />
          <a
            href="https://parentsandfamily.colostate.edu/how-to-gain-access/"
            target="_blank"
            rel="noopener noreferrer"
          >
            How do I gain access to the FAMweb system?
          </a>
        </Alert>
      </div>
    )

  const accounts = data.getMyAccounts.map((account) => <Account {...account} />)

  return (
    <div>
      <h2>Accounts</h2>
      {accounts}
    </div>
  )
}
