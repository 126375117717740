import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import idx from 'idx'

import Chip from '@material-ui/core/Chip'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

const query = gql`
  query getMyUser {
    getMyUser {
      email
      eduPersonPrincipalName
      givenName
      sn
      roles
      type
    }
  }
`

export default function UserInfo() {
  const { loading, error, data } = useQuery(query)

  if (loading) return null
  if (error) return `Error!: ${error}`

  const user = idx(data, (_) => _.getMyUser)

  if (!user) return null

  let loginBadge = null

  const isSpoofing = user.type === 'spoofed'
  const spoofingLabel = isSpoofing ? '(SPOOFING)' : ''
  const spoofingColor = isSpoofing ? 'secondary' : 'default'

  if (user.eduPersonPrincipalName.indexOf('@google.com') > -1) {
    loginBadge = (
      <Chip
        icon={<Icon className={'fab fa-google fa-fw'} />}
        label={`Logged in with Google as ${user.givenName} ${user.sn} ${spoofingLabel}`}
        variant="outlined"
        color={spoofingColor}
      />
    )
  } else if (
    user.eduPersonPrincipalName.indexOf('@microsoft.com') > -1 ||
    user.eduPersonPrincipalName.indexOf('@msn.com') > -1 ||
    user.eduPersonPrincipalName.indexOf('@outlook.com') > -1 ||
    user.eduPersonPrincipalName.indexOf('@hotmail.com') > -1 ||
    user.eduPersonPrincipalName.indexOf('@live.com') > -1
  ) {
    loginBadge = (
      <Chip
        icon={<Icon className={'fab fa-microsoft fa-fw'} />}
        label={`Logged in with Microsoft as ${user.givenName} ${user.sn} ${spoofingLabel}`}
        variant="outlined"
        color={spoofingColor}
      />
    )
  } else if (user.eduPersonPrincipalName.indexOf('@facebook.com') > -1) {
    loginBadge = (
      <Chip
        icon={<Icon className={'fab fa-facebook fa-fw'} />}
        label={`Logged in with Facebook as ${user.givenName} ${user.sn} ${spoofingLabel}`}
        variant="outlined"
        color={spoofingColor}
      />
    )
  } else if (user.eduPersonPrincipalName.indexOf('@linkedin.com') > -1) {
    loginBadge = (
      <Chip
        icon={<Icon className={'fab fa-linkedin fa-fw'} />}
        label={`Logged in with LinkedIn as ${user.givenName} ${user.sn} ${spoofingLabel}`}
        variant="outlined"
        color={spoofingColor}
      />
    )
  } else if (user.type === 'magic') {
    loginBadge = (
      <Chip
        icon={<Icon className={'fas fa-envelope fa-fw'} />}
        label={`Logged in with E-mail as ${user.givenName} ${user.sn} ${spoofingLabel}`}
        variant="outlined"
        color={spoofingColor}
      />
    )
  } else {
    loginBadge = (
      <Chip
        label={`Logged in with CSU eID as ${user.givenName} ${user.sn} ${spoofingLabel}`}
        variant="outlined"
        color={spoofingColor}
      />
    )
  }

  return (
    <div style={{ padding: 5, textAlign: 'right' }}>
      <Tooltip
        title={`${user.email}${
          user.eduPersonPrincipalName ? ` (${user.eduPersonPrincipalName})` : ''
        }`}
      >
        {loginBadge}
      </Tooltip>
    </div>
  )
}
