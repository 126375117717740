import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

export default withRouter(({ location }) => {
  useEffect(
    () => {
      const element = document.getElementById(location.hash.replace("#", ""));

      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop - 20 : 0
        });
      }, 100);
    },
    [location]
  );

  return (
    <div>
      <h2>FAMweb Frequently Asked Questions</h2>
      <Paper>
        <div style={{ padding: "30px" }}>
          <a href="#WhatisFAMweb">What is FAMweb?</a>
          <p>
            <a
              href="http://busfin.colostate.edu/Forms/ALR/CommercialCustomersFamWebFAQs.pdf#zoom=100"
              target="_blank"
              rel="noopener noreferrer"
            >
              Are you accessing a commercial account? Visit the FAQs for
              Commercial Accounts
            </a>
          </p>
          <p>
            <a href="#Categories">
              What information about my student can I see in FAMweb?
            </a>
          </p>
          <p>
            <a href="#OtherPaymentOptions">
              What other options does CSU have to pay the bill?
            </a>
          </p>
          <p>
            Please note: Login Credentials will be updated for FAMweb on October
            1, 2019. Please see below for information on this update.
            <br />
            <ul>
              <li>
                <a href="#NotYetMigrated">
                  I am a current FAMweb user but I have not yet updated my login
                  credentials to the new system. How do I do that?
                </a>
              </li>
              <li>
                <a href="#Social">
                  Why is the FAMweb login system being updated?
                </a>
              </li>
              <li>
                <a href="#Social2">
                  What does the new login system mean for FAMweb users?
                </a>
              </li>
              <li>
                <a href="#Social3">
                  Why does CSU support only Microsoft email accounts, Google
                  email accounts and Facebook?
                </a>
              </li>
              <li>
                <a href="#Social4">
                  What if I don't have an account with the Microsoft, Google or
                  Facebook providers?
                </a>
              </li>
              <li>
                <a href="#Social5">
                  What does Colorado State University contract for with Google,
                  Microsoft and Facebook?
                </a>
              </li>
              <li>
                <a href="#Social6">
                  Does Google, Microsoft or Facebook have access to my student’s
                  data?
                </a>
              </li>
              <li>
                <a href="#Social7">
                  Can Google, Microsoft or Facebook track what I do in FAMweb –
                  or will they even know I’ve logged into FAMweb?
                </a>
              </li>
              <li>
                <a href="#Social8">
                  What data does Google, Microsoft, and Facebook gather from
                  FAMweb that they can sell?
                </a>
              </li>
              <li>
                <a href="#Social9">
                  Does CSU make money on these partnerships with Google,
                  Microsoft, or Facebook?
                </a>
              </li>
              <li>
                <a href="#Social10">
                  Why is multi-factor authentication so important?
                </a>
              </li>
              <li>
                <a href="#Social12">
                  With the high-profile privacy catastrophes around Google,
                  Microsoft and Facebook, why should I trust them to validate my
                  identity?
                </a>
              </li>
            </ul>
          </p>
          <p>
            <a href="#url">How can I access the FAMweb website?</a>
          </p>
          <p>
            <a href="#CreateAccount">How do I create a FAMweb Account?</a>
          </p>
          <p>
            <a href="#NoStudentName">
              I've created my account, but I can't see my student's name.
            </a>
          </p>
          <p>
            <a href="#StudentPart">What does my student need to do?</a>
          </p>
          <p>
            <a href="#nostudentinfo">
              I can log in, but I can't see my student's information.
            </a>
          </p>
          <br />
          <h3 id="WhatisFAMweb">What is FAMweb?</h3>
          <p>
            FAMweb is a tool used by parents, family and friends to access
            information about a CSU student. In order to gain access to this
            information, your student must first grant access to you through
            their RamWeb account. After you have been granted access, you will
            receive an email with further instructions for logging into FAMweb.
            <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
          <br />
          <h3 id="OtherPaymentOptions">
            What other options does CSU have to pay the bill?
          </h3>
          <p>
            To see all the payment options, please visit{" "}
            <a
              href="https://financialaid.colostate.edu/paying-your-bill/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://financialaid.colostate.edu/paying-your-bill/
            </a>
            <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
          <Divider />
          <br />
          <h2 id="Social" style={{ backgroundColor: "yellow", padding: "4px" }}>
            Please note: Login Credentials will be updated for FAMweb on October
            1, 2019. Please see below for information on this update.
          </h2>
          <h3 id="NotYetMigrated">
            I am a current FAMweb user but I have not yet updated my login
            credentials to the new system. How do I do that?
          </h3>
          <p>
            <strong>
              BEFORE October 1: If you are a current FAMweb user and your
              student has already invited you to use FAMweb, you can continue
              your access by following these steps.
            </strong>
            <ol>
              <li>
                Go to{" "}
                <a
                  href="https://famweb-prod.is.colostate.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://famweb-prod.is.colostate.edu/
                </a>
              </li>
              <li>
                You will select the box that says, “FAMweb Members without
                Updated Authentication” and login with your current FAMweb
                credentials.
              </li>
              <li>
                The information for updating will now be provided step by step.
              </li>
              <li>
                You will be prompted to login with one of three login options.
                You will choose the one that is best for you. Google (gmail.com
                account), Microsoft (msn.com account, Hotmail.com account or
                outlook.com account) or Facebook. Please click your preference.
              </li>
              <li>
                Once you click an option, the FAMweb system will request you to
                login to that provider. For example, if you chose Google, it
                will prompt you to login to a gmail.com account.
              </li>
              <li>
                You will next be directed to your FAMweb page and asked to
                accept the FERPA agreement. Please click “I agree”
              </li>
              <li>
                You will now see your student’s name and information on the
                screen. Once you click your student’s name, the full information
                should appear.
              </li>
            </ol>
            <br />
            <strong>
              AFTER October 1: If you are a current FAMweb user but have not
              updated your login credentials by October 1 you will need to
              re-gain FAMweb access by following these steps.
            </strong>
            <ol>
              <li>
                Your current login information will not be valid. Your CSU
                student will need to re-invite you to FAMweb. For information on
                what your student’s need to do, go here (
                <a href="#StudentPart">What does my student need to do?</a>)
              </li>
              <li>
                Once your student has re-invited you, you will receive an email
                with further instructions sent to the email address utilized by
                your student when giving you access.
              </li>
              <li>
                This verification email will direct you to visit{" "}
                <a
                  href="https://famweb-prod.is.colostate.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://famweb-prod.is.colostate.edu/
                </a>
              </li>
              <li>
                You will select the box on the right that says, “Current FAMweb
                members”
              </li>
              <li>
                You will be prompted to login with one of three login options.
                You will choose the one that is best for you. Google (gmail.com
                account), Microsoft (msn.com account, Hotmail.com account or
                outlook.com account) or Facebook. Please click your preference.
              </li>
              <li>
                Once you click an option, the FAMweb system will request you to
                login to that provider. For example, if you chose Google, it
                will prompt you to login to a gmail.com account.
              </li>
              <li>
                You will next be directed to your FAMweb page and asked to
                accept the FERPA agreement. Please click “I agree”
              </li>
              <li>
                You will now see your student’s name and information on the
                screen. Once you click your student’s name, the full information
                should appear.
              </li>
            </ol>
          </p>
          <h3>Why is the FAMweb login system being updated?</h3>
          <p>
            We have heard from many of you, our FAMweb constituents, that you
            have trouble recalling the previously utilized login credentials
            which included a separate user account and password. In addition, as
            technology evolves, CSU is committed to keeping your student’s data
            safe and secure. The current methods FAMweb uses to verify the
            identity of a person outside of CSU requesting access to student
            data can be strengthened by leveraging the existing robust
            authentication methods of several email and social services that
            have been vetted by CSU’s security team. This updated form of
            sign-on uses existing information from a verified networking service
            to access a website instead of creating a new login and password
            specifically for that site.
          </p>
          <h3 id="Social2">
            What does the new login system mean for FAMweb users?
          </h3>
          <p>
            CSU is leveraging the power of email accounts from Microsoft
            (msn.com and Hotmail.com), Google (gmail.com) and/or Facebook logins
            to provide an additional layer of identity verification. These
            service providers were selected because they encourage strong
            passwords and provide support for multi-factor authentication of
            login credentials. While FAMweb uses Google, Microsoft and Facebook
            to validate your identity, your student’s data remains protected on
            campus at CSU. Once you have been validated, FAMweb interacts with
            you directly and can provide the following information. (Please
            note, current CSU faculty and staff can continue to use their
            existing logins through the university’s electronic ID or eID
            system.)
          </p>
          <h3 id="Social3">
            Why does CSU support only Microsoft email accounts, Google email
            accounts and Facebook?
          </h3>
          <p>
            To ensure that your account credentials are secure, CSU only
            supports these providers that both encourage strong passwords and
            provide support for multi-factor authentication. For this reason,
            FAMweb only allows logins from Microsoft (msn.com and Hotmail.com),
            Google (gmail.com), and Facebook, as well as CSU faculty and staff
            accounts that are part of the University electronic ID (eID) system.
          </p>
          <h3 id="Social4">
            What if I don't have an account with the Microsoft, Google or
            Facebook providers?
          </h3>
          <p>
            If you do not already have access through one of these providers, we
            encourage you to create an account with your preferred provider to
            ensure continued access to FAMweb. We understand that creating a new
            account on one of these services may bring some discomfort for those
            who prefer to keep their online identities more private or maintain
            no online identities at all. However, implementing this new step is
            critical to creating a more secure system for our students’ data.
            The previous FAMweb login system will be removed as of October 1,
            2019 and you will not be able to access FAMweb without these updated
            login credentials.
          </p>
          <h3 id="Social5">
            What does Colorado State University contract for with Google,
            Microsoft and Facebook?
          </h3>
          <p>
            Nothing. CSU does NOT contract with these providers. CSU partners
            with a third-party identity provider (Cirrus Identity) to be a
            liaison between the university and these providers. The only data
            that CSU provides to Cirrus Identity is the FAMweb member’s choice
            of provider to use for authentication. Cirrus then queries the
            provider to validate the trusted individual’s login credentials. The
            provider lets Cirrus know either, Yes – the person has validated
            their credentials, or No – they did not. Cirrus then tells FAMweb
            either Yes – let them connect to the FAMweb service, or No – keep
            them out.
          </p>
          <h3 id="Social6">
            Does Google, Microsoft or Facebook have access to my student’s data?
          </h3>
          <p>
            No. All FAMweb data is secure behind CSU’s firewalls and security.
            If a trusted individual’s credentials are validated, the FAMweb
            application authorizes access to their student’s data. Google,
            Microsoft, and Facebook have no way of knowing that you have been
            passed off to FAMweb.
          </p>
          <h3 id="Social7">
            Can Google, Microsoft or Facebook track what I do in FAMweb – or
            will they even know I’ve logged into FAMweb?
          </h3>
          <p>
            No. Since FAMweb uses a third-party identity service to authenticate
            a trusted individual’s login credentials, these services have no way
            of learning that the request is coming from FAMweb or from CSU.
          </p>
          <h3 id="Social8">
            What data does Google, Microsoft, and Facebook gather from FAMweb
            that they can sell?
          </h3>
          <p>
            None. Since there is never a direct connection between FAMweb and
            these services, Google, Microsoft, and Facebook DO NOT ever know
            that the request for authentication is coming from FAMweb.
            Similarly, with the FAMweb data secure behind CSU’s firewalls, these
            providers DO NOT have the ability to gather any information about
            you or your student.
          </p>
          <h3 id="Social9">
            Does CSU make money on these partnerships with Google, Microsoft, or
            Facebook?
          </h3>
          <p>
            No. Since the providers are not aware that these requests are coming
            from CSU, and since CSU DOES NOT have any contracts in place with
            these providers, there is no financial incentive for CSU. This
            simply provides CSU a cost-effective method to provide stronger and
            sustainable security for you to access your student’s information
            while keeping tuition costs low.
          </p>
          <h3 id="Social10">
            Why is multi-factor authentication so important?
          </h3>
          <p>
            Multi-factor authentication allows you to keep your data secure even
            if a hacker is able to obtain your login information. Banks have
            been using this for a long time; you need two forms of information –
            something you know (your PIN number) and something you have (your
            bank card) – to be able to access your bank account electronically.
            With multi-factor authentication, you can be sure that if your
            password is “stolen” in a security breach, it will not be able to be
            used. Colorado State University now uses multi-factor authentication
            for anyone accessing services that require CSU login credentials
            from outside the university, and will be expanding the requirement
            to all CSU administrative systems in the future.
          </p>
          <h3 id="Social12">
            With the high-profile privacy catastrophes around Google, Microsoft
            and Facebook, why should I trust them to validate my identity?
          </h3>
          <p>
            We can understand your concern around these providers, given the
            privacy issues that make the news. Rest assured that your students’
            data is not being shared with these providers and the only
            information a hacker can gather from Google, Microsoft, or Facebook
            is information you choose to post there. If you set up multi-factor
            authentication, a hacker cannot log into your account even if they
            are able to “steal” your login and password information.
          </p>
          <p>
            These big companies employ large staffs whose sole purpose is to
            protect against these hackers – and yet we all know breaches still
            occur. CSU cannot provide the same level of staffing without
            impacting your student’s tuition dollars. We encourage you to set up
            multi-factor authentication wherever possible.
          </p>
          <a href="#top">Return to Questions</a>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="Categories">
            What information about my student can I see in FAMweb?
          </h3>
          <p>The information categories are grouped into:</p>
          <ul>
            <li>Billing Information</li>
            <li>Financial Aid</li>
            <li>Grades for Last Completed Term</li>
            <li>Tax Information</li>
            <li>Unofficial Transcripts</li>
            <li>Weekly Class Schedule</li>
          </ul>
          Each specific information category that can be accessed must be
          granted by the student to each individual. <br />
          <br />
          <a href="#top">Return to Questions</a>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="url">How can I access the FAMweb website?</h3>
          <p>
            From a web browser go to the url
            https://famweb-prod.is.colostate.edu/ or click&nbsp;
            <a
              href="https://famweb-prod.is.colostate.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAMweb
            </a>
            <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="CreateAccount">How do I create a FAMweb account?</h3>
          <p>
            *Please note, you will not be able to create a FAMweb account until
            your student has first invited you to join FAMweb and granted you
            access. Students grant FAMweb access via their RAMweb account.
          </p>
          <ul>
            <li>
              Once you have been invited to use FAMweb, you will receive an
              email to the email account that was registered. This email will
              include instructions.
            </li>
            <li>
              Using a web browser, go to &nbsp;
              <a
                href="https://famweb-prod.is.colostate.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://famweb-prod.is.colostate.edu/
              </a>
            </li>
            <li>
              If you have not agreed to the Family Educational Rights and
              Privacy Act policy within the last year, you will be asked to
              accept the FERPA agreement.
            </li>
            <li>
              If you do not see your student's name(s) listed on the Welcome to
              FAMweb page, you must contact your student to grant access to you
              using the same email address you created the account for. <br />
              Refer to&nbsp;
              <a href="#StudentPart">What does my student need to do?</a> on
              this page.
            </li>
          </ul>
          <a href="#top">Return to Questions</a>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="NoStudentName">
            I've created my account, but I can't see my student's name.
          </h3>
          <p>
            Creating an account is only part of the process. Even with an
            account you will not be able to access any of your student's
            information until they grant you access. Refer to &nbsp;
            <a href="#StudentPart">What does my student need to do?</a> on this
            page to make this grant. Your student will have to know the exact
            email address you used to create your account.
            <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="StudentPart">What does my student need to do?</h3>
          <p>Your student will need to:</p>
          <ul>
            <li>
              Login to&nbsp;
              <a
                href="https://ramweb.colostate.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                RAMweb
              </a>
              &nbsp;and select the "Change My Access to My Records (FAMweb)"
              option under the "Records" heading.
            </li>
            <li>
              On the page that opens, complete the "Add Someone to Your FAMweb
              Access List" section.
            </li>
            <li>
              Enter an email address for the person being granted FAMweb access.
            </li>
            <li>Select the "Add" button. </li>
            <li>Select the "Continue" button. </li>
            <li>Select the "Edit Permissions" button.</li>
            <li>
              Click the checkbox next to each of the&nbsp;
              <a href="#Categories">information categories</a> they wish to
              grant you access to. <br />
              There will be one set of checkboxes for each individual the
              student has added to their FAMweb Access List.
            </li>
          </ul>
          <a href="#top">Return to Questions</a>
          <br />
          <br />
          <Divider />
          <br />
          <Divider />
          <br />
          <h3 id="login trouble">I'm having trouble logging in to FAMweb.</h3>
          <p>
            Confirm you are logging in using the same email address you used to
            create your account.
          </p>
          <p>
            Confirm you have completed all the steps of the{" "}
            <a href="#CreateAccount">account creation</a> process.
          </p>
          <a href="#top">Return to Questions</a>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="nostudentinfo">
            I can log in, but I can't see my student's information.
          </h3>
          <p>
            Confirm your student has granted you access utilizing a valid email
            address and that you have utilized that same email address to set-up
            your account. If you see your student’s name on the FAMweb page,
            please click the student’s name to reveal all information. (Refer
            to&nbsp;
            <a href="#StudentPart">"What does my student need to do?"</a> on
            this page.)
            <br />
            Confirm your student has granted you access to the information
            category you are trying to see. (Refer to&nbsp;
            <a href="#StudentPart">"What does my student need to do?"</a> on
            this page.)
            <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="clearcache">How do I clear my web browser's cache?</h3>
          <p>
            This will depend on which browser and version you use. <br />
            You can do a web search on clearing a web browser cache on a search
            site such as&nbsp;
            <a
              href="http://www.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google
            </a>
            . <br />
            One site that shows how to clear the cache for 9 different browsers
            is&nbsp;
            <a
              href="http://www.wikihow.com/Clear-Your-Browser%27s-Cache"
              target="_blank"
              rel="noopener noreferrer"
            >
              wikiHow
            </a>
            . <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
          <br />
          <br />
          <Divider />
          <br />
          <h3 id="help">Who can I contact for help?</h3>
          <p>
            Send an email to the CSU IT Help Desk at help@colostate.edu or phone
            (970) 491-7276 <br />
            <br />
            <a href="#top">Return to Questions</a>
          </p>
        </div>
      </Paper>
    </div>
  );
});
