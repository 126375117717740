import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Typography from '@material-ui/core/Typography'

import LoginListItem from './LoginListItem'
import StudentListItem from './StudentListItem'

const query = gql`
  query getAccount($gidm: ID!) {
    getAccount(gidm: $gidm) {
      firstName
      lastName
      gidm
      email
      logins {
        eduPersonPrincipalName
        type
      }
      students {
        fullName
        csuId
        authorizations {
          code
          type {
            description
          }
        }
      }
    }
  }
`

export default function Account() {
  const { gidm } = useParams()
  const { loading, error, data } = useQuery(query, { variables: { gidm } })

  if (loading) return <CircularProgress />
  if (error) return <div>Error!: ${error}</div>

  const {
    getAccount: { firstName, lastName, email, students, logins },
  } = data

  const displayName =
    !firstName || !lastName ? email : `${firstName} ${lastName}`

  return (
    <div>
      <h2>Admin</h2>
      <h4>User Account Details</h4>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {displayName}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                E-mail: {email}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                GIDM: {gidm}
              </Typography>
            </CardContent>
          </Card>
          <br />
          <Card>
            {logins && (
              <List
                dense
                subheader={
                  <ListSubheader>{displayName}'s Linked Logins</ListSubheader>
                }
              >
                {logins.map((login) => (
                  <LoginListItem email={email} gidm={gidm} login={login} />
                ))}
                {logins && logins.length < 1 && (
                  <React.Fragment>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={`The are currently no logins linked to ${firstName}'s account.`}
                      />
                    </ListItem>
                  </React.Fragment>
                )}
              </List>
            )}
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            {students && (
              <List
                dense
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {displayName}'s Associated Accounts
                  </ListSubheader>
                }
              >
                {students.map((student) => (
                  <StudentListItem gidm={gidm} student={student} />
                ))}
              </List>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
