import React from 'react'
import { Route, Switch } from 'react-router-dom'

import MaintenanceMessage from './MaintenanceMessage'
import Faq from './pages/Faq'

import AuthRoute from './auth/AuthRoute'
import AdminRoute from './auth/AuthRoute'
import FerpaRoute from './auth/FerpaRoute'

import LoginOptions from './pages/auth/LoginOptions'
import MagicLink from './pages/auth/MagicLink'

import UserInfo from './pages/auth/UserInfo'
import Ferpa from './pages/account/Ferpa'

import Admin from './pages/admin/Search'
import AdminResults from './pages/admin/Results'
import AdminAccount from './pages/admin/Account'

import Accounts from './pages/Accounts'
import Home from './pages/Home'
import Grades from './pages/link/Grades'
import UnofficialTranscript from './pages/link/UnofficialTranscript'
import WeeklySchedule from './pages/link/WeeklySchedule'

import ResponsiveDrawer from './ResponsiveDrawer'

import NotFound from './pages/NotFound'

export default function Routes() {
  return (
    <div>
      <MaintenanceMessage />
      <UserInfo />
      <Switch>
        <Route exact path="/drawer" component={ResponsiveDrawer} />
        <FerpaRoute exact path="/" component={Accounts} />

        <FerpaRoute exact path="/home" component={Home} />
        <AuthRoute exact path="/ferpa" component={Ferpa} />

        <Route exact path="/login" component={LoginOptions} />
        <Route exact path="/login/email" component={MagicLink} />

        <Route exact path="/faq" component={Faq} />
        <Route exact path="/faqs" component={Faq} />

        <FerpaRoute
          exact
          path="/link/:gidm/:pidm/AREG_LCTGRDS"
          component={Grades}
        />
        <FerpaRoute
          exact
          path="/link/:gidm/:pidm/AREG_UXSCRPT"
          component={UnofficialTranscript}
        />
        <FerpaRoute
          path="/link/:gidm/:pidm/AREG_WKSCHED/:term?"
          component={WeeklySchedule}
        />

        <AdminRoute exact path="/admin" component={Admin} />
        <AdminRoute
          exact
          path="/admin/search/:email"
          component={AdminResults}
        />
        <AdminRoute
          exact
          path="/admin/account/:gidm"
          component={AdminAccount}
        />

        <Route component={NotFound} />
      </Switch>
    </div>
  )
}
