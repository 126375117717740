// Client side resolvers for queries / mutations annotated with @client directive
export default {
  Mutation: {
    logout: (_, args, { cache }) => {
      if (localStorage.getItem('spoof_token') !== null) {
        localStorage.removeItem('spoof_token')

        if (window.location === '/') {
          window.location.reload()
        } else {
          window.location = '/'
        }
      } else {
        window.location = '/auth/logout'
      }

      return null
    },
    updateUserMetadata: (_, { authPayload }, { cache }) => {
      const data = {
        userMetadata: {
          __typename: 'AuthPayload',
          ...authPayload,
        },
      }
      localStorage.setItem('spoof_token', authPayload.token)
      cache.writeData({ data })
      return null
    },
  },
}
