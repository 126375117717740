import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

const query = gql`
  query searchAccounts($email: String) {
    searchAccounts(email: $email) {
      firstName
      lastName
      gidm
      email
    }
  }
`

export default function Results() {
  const { email } = useParams()
  const { loading, error, data } = useQuery(query, { variables: { email } })

  if (loading) return <CircularProgress />
  if (error) return <div>Error!: {error.toString()}</div>

  return (
    <div>
      <h2>Admin</h2>
      <h4>Search Results</h4>

      {data.searchAccounts.length < 1 && <div>No results</div>}
      {data.searchAccounts.length > 0 && (
        <Paper>
          <List component="nav">
            {data.searchAccounts.map(({ firstName, lastName, email, gidm }) => (
              <ListItem button component={Link} to={`/admin/account/${gidm}`}>
                <ListItemText
                  primary={
                    !firstName || !lastName ? email : `${firstName} ${lastName}`
                  }
                  secondary={email}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <br />
      <Link to="/admin">Back to Search</Link>
    </div>
  )
}
