import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import idx from 'idx'

import LogoutLink from './LogoutLink'

const query = gql`
  query getMyUser {
    getMyUser {
      email
      eduPersonPrincipalName
      givenName
      sn
      roles
    }
  }
`

export default function LoginOrLogout({ type }) {
  const { data } = useQuery(query)

  return idx(data, (_) => _.getMyUser) ? <LogoutLink type={type} /> : null
}
