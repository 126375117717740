import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

const query = gql`
  query {
    getMyStudents {
      fullName
      pidm
      authorizations {
        code
        type {
          id
          description
          email
        }
        activityDate
        startDate
        endDate
      }
    }
  }
`

export default function Home() {
  const [expanded, setExpanded] = React.useState(null)
  const { loading, error, data } = useQuery(query)

  if (loading) return <div>Loading...</div>
  if (error) return `Error!: ${error}`

  const handleChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false)
  }

  let students = []

  const icons = {
    AREG_LCTGRDS: 'fa fa-fw fa-user-graduate',
    AREG_WKSCHED: 'fa fa-fw fa-calendar-alt', //schedule image
    AROS_BILLING: 'fa fa-fw fa-file-invoice',
    AREG_GLOBAL: 'fa fa-fw fa-info-circle',
    AREG_UXSCRPT: 'fa fa-fw fa-file-alt', //Transcript img
    AROS_TAX: 'fa fa-fw fa-file-invoice-dollar',
    AREG_FINAID: 'fa fa-fw fa-dollar-sign',
  }

  const token = window.localStorage.getItem('spoof_token') || ''

  if (data.getMyStudents) {
    students = data.getMyStudents.map((student) => (
      <ExpansionPanel
        key={`${student.pidm}`}
        expanded={expanded === student.pidm}
        onChange={handleChange(student.pidm)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{student.fullName}</Typography>
        </ExpansionPanelSummary>
        <div>
          <Divider />
          <List>
            {student.authorizations.map((authorization) => (
              <ListItem
                key={`${student.pidm}-${authorization.code}`}
                component={
                  ['AROS_BILLING', 'AREG_FINAID', 'AROS_TAX'].indexOf(
                    authorization.code
                  ) > -1
                    ? 'a'
                    : Link
                }
                to={`/link/${student.pidm}/${authorization.code}`}
                href={`/external/link/${student.pidm}/${authorization.code}?token=${token}`}
                button
              >
                <ListItemAvatar>
                  <Avatar>
                    <Icon className={icons[authorization.code]} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={authorization.type.description}
                  secondary={'Granted access on ' + authorization.activityDate}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </ExpansionPanel>
    ))
  }

  if (students.length < 1)
    return (
      <div>
        <h2>No accounts</h2>
        <p>There are no accounts to display.</p>
      </div>
    )

  return (
    <div>
      <h2>Accounts</h2>
      {students}

      <br />
      <h3>Privacy Policies</h3>
      <p>
        The information accessible from this page is subject to the{' '}
        <a href="https://registrar.colostate.edu/student-resources/ferpa-student-privacy/">
          Student Privacy (FERPA) Policies
        </a>{' '}
        and the{' '}
        <a href="http://sfs.colostate.edu/payingyourbill/studentbillingstatement.aspx">
          Office of Financial Aid Privacy Policy
        </a>
        .
      </p>
    </div>
  )
}
