import React from 'react'

import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Tooltip from '@material-ui/core/Tooltip'

import SendInvitationButton from './SendInvitationButton'

export default function StudentListItem({
  gidm,
  student: { fullName, csuId, authorizations },
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <Divider />

      <Tooltip title="Expand to show access">
        <ListItem button onClick={() => setOpen(!open)}>
          <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          <ListItemText primary={`${fullName} (${csuId})`} />
          <ListItemSecondaryAction>
            <SendInvitationButton gidm={gidm} requester={csuId}>
              Resend Invitation
            </SendInvitationButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Tooltip>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {authorizations.map((authorization) => (
            <ListItem button>
              <ListItemIcon>
                <CheckBoxIcon />
              </ListItemIcon>
              <ListItemText
                primary={<span>{authorization.type.description} </span>}
              />
              <ListItemSecondaryAction>
                <Chip label={authorization.code} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  )
}
