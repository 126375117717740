import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'

// import ISTheme from '@is/infosys-theme/public/js/react'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { CookiesProvider } from 'react-cookie'

import { client } from './client'
import theme from './theme'
import AuthProvider from './auth/AuthProvider'
import Routes from './Routes'
import SideMenu from './SideMenu'
import TopMenu from './TopMenu'
import ISTheme from './ISTheme'

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <CookiesProvider>
            <BrowserRouter>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <ISTheme
                  headerTitle={'FAMweb'}
                  topMenu={<TopMenu />}
                  main={<Routes />}
                  sideMenu={<SideMenu />}
                  // headerAlert="test"
                  // headerMessage="test"
                />
              </SnackbarProvider>
            </BrowserRouter>
          </CookiesProvider>
        </AuthProvider>
      </ApolloProvider>
    </MuiThemeProvider>
  )
}
