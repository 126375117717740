import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

export default function Search() {
  let history = useHistory()

  return (
    <div>
      <h2>Admin</h2>
      <h4>Search for Account</h4>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          let errors = {}
          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }
          return errors
        }}
        onSubmit={(values) =>
          history.push(`/admin/search/${encodeURI(values.email)}`)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Card>
            <CardContent>
              <form ref="form" onSubmit={handleSubmit}>
                {isSubmitting && <CircularProgress size={24} />}
                {errors.email && touched.email && errors.email}

                <TextField
                  required
                  ref="email"
                  style={{ width: '100%' }}
                  name="email"
                  label="E-mail"
                  hintText="E-mail"
                  floatingLabelText="E-mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                  margin="normal"
                />

                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Search
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </div>
  )
}
