import React from 'react'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Formik } from 'formik'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import SendIcon from '@material-ui/icons/Send'
import TextField from '@material-ui/core/TextField'

const mutation = gql`
  mutation sendMagicLink($email: String!) {
    sendMagicLink(email: $email)
  }
`

export default function MagicLink() {
  const { enqueueSnackbar } = useSnackbar()
  const [sendMagicLink] = useMutation(mutation)

  return (
    <div>
      <h2>Login Link</h2>
      <h4>Send a Login Link to My E-mail</h4>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          let errors = {}

          if (!values.email) {
            errors.email = 'Required'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address'
          }

          return errors
        }}
        onSubmit={async (values, actions) => {
          try {
            await sendMagicLink({ variables: { email: values.email } })
            enqueueSnackbar('Login Link sent!', { variant: 'success' })
          } catch (err) {
            enqueueSnackbar(
              err?.graphQLErrors[0]?.message ||
                'An unexpected error has occurred.  Please try again later.',
              { variant: 'error' }
            )
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Card>
            <CardContent>
              <form ref="form" onSubmit={handleSubmit}>
                {isSubmitting && <CircularProgress size={24} />}
                {errors.email && touched.email && errors.email}

                <TextField
                  required
                  ref="email"
                  style={{ width: '100%' }}
                  name="email"
                  label="E-mail"
                  hintText="E-mail"
                  floatingLabelText="E-mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                  margin="normal"
                />

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  endIcon={<SendIcon />}
                >
                  Send Login Link
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </div>
  )
}
