import React from 'react'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { AuthContext } from './AuthProvider'
import AuthRoute from './AuthRoute'

export default ({ component: Component, ...rest }) => {
  const [cookies] = useCookies(['ferpa'])

  return (
    <AuthContext.Consumer>
      {(context) => {
        const newComponent =
          context.user &&
          ((context.user.roles.includes('ADMIN') &&
            context.user.type === 'spoofed') ||
            cookies.ferpa === context.user.email) ? (
            <Component {...rest} />
          ) : (
            <Redirect to="/ferpa" />
          )
        return <AuthRoute {...rest} component={() => newComponent} />
      }}
    </AuthContext.Consumer>
  )
}
