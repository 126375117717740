import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import PrintIcon from '@material-ui/icons/Print'

const query = gql`
  query getUnofficialTranscript($pidm: ID!) {
    getUnofficialTranscript(pidm: $pidm)
  }
`

export default function UnnofficialTranscript() {
  const { pidm } = useParams()
  const { loading, error, data } = useQuery(query, { variables: { pidm } })

  if (loading) return <CircularProgress />
  if (error) return `Error!: ${error}`

  return (
    <div>
      <h2>Unnofficial Transcript</h2>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => window.print()}
        >
          Print This Page
        </Button>
      </div>
      <br />
      <Paper style={{ padding: 20 }} elevation={1}>
        {!loading && data && (
          <div
            dangerouslySetInnerHTML={{
              __html: data.getUnofficialTranscript,
            }}
          />
        )}
      </Paper>
    </div>
  )
}
