import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    chip: {
      margin: theme.spacing.unit / 4,
      marginRight: theme.spacing.unit / 2,
    },
    section1: {
      margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    },
    section2: {
      margin: theme.spacing.unit * 2,
    },
    section3: {
      margin: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 2}px ${theme
        .spacing.unit * 2}px`,
    },
  })
)

export default function SocialOption({ socialUrl }) {
  const classes = useStyles()

  return (
    <Paper style={{ padding: '10px', height: '100%' }}>
      <div className={classes.root}>
        <div className={classes.section1}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography gutterBottom variant="h5">
                Current FAMweb Members
              </Typography>
            </Grid>
          </Grid>
          <Typography color="textSecondary">
            Choose this option if your login is set to authenticate with Google,
            Microsoft or Facebook.
            <br />
            <br />
            To ensure access, use the same address that was invited to use
            FAMweb.
          </Typography>
        </div>
        <Divider variant="middle" />

        <div className={classes.section3}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            href={socialUrl}
          >
            Login&nbsp;with&nbsp;your secure&nbsp;credentials
          </Button>
        </div>
      </div>
    </Paper>
  )
}

SocialOption.propTypes = {
  socialUrl: PropTypes.string.isRequired,
}
