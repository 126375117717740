import React from "react";
import { withRouter, Route } from "react-router-dom";
import queryString from "qs";

import { AuthContext } from "./AuthProvider";
import LoginOptions from "../pages/auth/LoginOptions";


export default withRouter(({ component: Component, location, ...rest }) => {
  const params = queryString.parse(location.search, {
    ignoreQueryPrefix: true
  });

  if (params.token) {
    localStorage.setItem("spoof_token", params.token);
    window.location = window.location.pathname;
  }

  return (
    <AuthContext.Consumer>
      {({ user }) => (
        <Route
          {...rest}
          render={props =>
            user ? (
              <Component {...props} />
            ) : (
              <LoginOptions {...user} />
            )
          }
        />
      )}
    </AuthContext.Consumer>
  );
});
